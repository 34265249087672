<template>
  <div>
    <el-select size="mini" @change="handleChange" v-model="currentQuarter" placeholder="请选择">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'HcYmhfBudgetYear',

  data() {
    return {
      currentQuarter: null,
      options: [
        { label: '第一季度', value: 1 },
        { label: '第二季度', value: 2 },
        { label: '第三季度', value: 3 },
        { label: '第四季度', value: 4 }
      ]
    }
  },

  created() {
    this.generateQuarter()
  },

  methods: {
    generateQuarter() {
      const now = new Date()
      const month = now.getMonth() + 1
      if (month <= 3) {
        this.currentQuarter = 1
      } else if (month <= 6) {
        this.currentQuarter = 2
      } else if (month <= 9) {
        this.currentQuarter = 3
      } else {
        this.currentQuarter = 4
      }
      this.$emit('refreshQuarter', this.currentQuarter, this.options)
    },
    handleChange(value) {
      this.currentQuarter = value
      this.$emit('refreshQuarter', this.currentQuarter, this.options)
    }
  }
}
</script>

<style lang="scss" scoped></style>
